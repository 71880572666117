<template>
    <div>
        <div></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {                
            }
        },
    }

</script>

<style lang="scss" scoped>
</style>